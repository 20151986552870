@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=B612:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Playfair+Display&display=swap');
@import '_home.scss';
@import '_nav.scss';
@import '_skills.scss';
@import '_aboutMe.scss';
@import '_experience.scss';
@import '_projects.scss';
@import '_footer.scss';


@import 'swiper/swiper.scss';
@import 'swiper/components/navigation/navigation.scss';
@import 'swiper/components/pagination/pagination.scss';
@import 'swiper/components/scrollbar/scrollbar.scss';
@import 'swiper/components/effect-coverflow/effect-coverflow.scss';

@font-face{
  font-family: Atkinson-Hyperlegible-Regular-102;
  src: url(fonts/Atkinson-Hyperlegible-Regular-102.ttf);
}

@font-face{
  font-family: Atkinson-Hyperlegible-Bold-102;
  src: url(fonts/Atkinson-Hyperlegible-Bold-102.ttf);
}

@font-face{
  font-family: Atkinson-Hyperlegible-Italic-102;
  src: url(fonts/Atkinson-Hyperlegible-Italic-102.ttf);
}

@font-face{
  font-family: Atkinson-Hyperlegible-BoldItalic-102;
  src: url(fonts/Atkinson-Hyperlegible-BoldItalic-102.ttf);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body{
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}

a:focus, a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}

.landingPage-wrapper{
  background: 20%/ 5em;
  background-image: linear-gradient( 76.3deg,  rgba(44,62,78,1) 12.6%, rgba(69,103,131,1) 82.8% );
  transition: .8s ease-in-out;
  animation: change 4s ease-in-out infinite alternate;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.construction-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 720px;
  height: 550px;
  background: rgba(225, 225, 225, 0.1);
  box-shadow: 0 15px 35px rgba(0,0,0,0.2);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(0,0,0,0.2);
  border-left: 1px solid rgba(0,0,0,0.2);
  animation: fade-in 1.2s ease;
  div{
    font-family: 'Cinzel', serif;
    font-size: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
}

@keyframes change {
  0%{background-position:30% 0%}
  50%{background-position:51% 100%}
  100%{background-position:30% 0%}
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  50%{
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
 10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
 20% { transform: rotate(-8.0deg) }
 30% { transform: rotate(14.0deg) }
 40% { transform: rotate(-4.0deg) }
 50% { transform: rotate(10.0deg) }
 60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
100% { transform: rotate( 0.0deg) }
}


@keyframes image_blur {
  0% { -webkit-filter: blur(0px);}
50% { -webkit-filter: blur(5px);}
100% { -webkit-filter: blur(0px);}
}
