#root{
  min-height: 100vh;
  background-color:#fdf6f6;
  font-family: Atkinson-Hyperlegible-Regular-102;
  width: 100%;
}

.homePage-wrapper{
  padding-top: 65px;
}

.fullName{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Atkinson-Hyperlegible-Bold-102;
  font-size: 30px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 30em;
  animation: type 4.6s steps(60, end); 
  // animation: fade-in 8s forwards;
}

@keyframes type{ 
  from { width: 0; } 
} 


header{
  // background: url('.././images/blackScales.png');
  // // background: #ACA7CB;
  // background-position: center;
  // background-size: cover;
  // min-height: 100vh;
  width: 100%;
  // position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  color:#4a4a4a;
}

.h1{
  font-family: 'DM Serif Display', serif;
  // font-family: 'Playfair Display', serif;
  font-size: 195px;
  letter-spacing: 0.08em;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  background: url('.././images/blackScales.png');
  // background: #ACA7CB;
  background-position: center;
  background-size: cover;
  display: flex;
  overflow: hidden;
  justify-content: center;
  .first-name {
    margin-right: 72px;
  }
  .first-name, .second-name{
    display: flex;
    span{
      display: inline-block;
            position: relative;
    }
  }
}


.title{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 125px;
  // transform: translateY(45%);

}

h3{
  font-size: 30px;
  letter-spacing: 0.08em;
  margin-top: 20px;
  text-transform: uppercase;
  font-family: Atkinson-Hyperlegible-Bold-102;
}

@media (max-width: 1200px) {
  .homePage-wrapper{
    // min-height: 100vh;
    padding-top: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .title{
    margin-top: 0;
  }
  .h1{
    
    font-size: 165px;
  }
  h3{
    font-size: 34px;
  }
}

@media (max-width: 1000px) {
  .homePage-wrapper{
    padding-top: 250px;
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .title{
    margin-top: 0;
  }
  .h1{
    font-size: 130px;
  }
  h3{
    font-size: 20px;
  }
}

@media only screen and (max-width: 699px){
  .homePage-wrapper{
    padding-top: 150px;
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .h1{
    font-size: 70px;
    .first-name {
      margin-right: 32px;
    }
  }
  h3{
    font-size: 21px;
  }
}