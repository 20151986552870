.experience-wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 40px;
  // margin-left: 100px;
}

.swiper-container{
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}




.experience-title{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  .xp-imgBx{
    display: flex;
    align-items: center;
    padding-right: 10px;
    img{
      width: 50px;
      height: 50px;
      border-radius: 5px;
    }
  }
  h3{
    margin: 0;
    font-size: 25px;
  }
}

.xp-div{
  background-color: #fdf6f6;
  margin: 10px auto;
  width: 100%;
}


.experience-date{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Atkinson-Hyperlegible-Regular-102;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  width: 100%;
}

.experience-card{
  // border:  3px solid  #fdf6f6;
  height: 100%;
  border-radius: 10px;
  width: 100%;
  padding: 20px 30px 20px;
  color: #fdf6f6;
  background-color: #787586;

  .experience-content{
    line-height: 1.5em;
    div{
      margin-bottom: 20px;
    }
    li{
      margin-bottom: 10px;
      margin-left: 40px;
    }
  }
}

@media (max-width: 699px){

  .experience-card{
    padding: 20px 35px 20px;
  
    .experience-content{
      font-size: 14px;
      div{
        margin-bottom: 20px;
      }
      li{
        margin-bottom: 5px;
      }
    }
  }

  .experience-title{
    margin-bottom: 5px;
    h3{
      margin: 0;
      font-size: 17px;
    }
  }

}
