// CONTACT OVERLAY AND FOOTER

.contact-title{
  font-size: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  // text-transform: uppercase;
  margin-top: -10px;
  margin-bottom: 30px;
  h4{
    margin: 0;
    font-family: Atkinson-Hyperlegible-Bold-102;
    font-size: 35px;
    letter-spacing: 0.08em;
  }
}

.footer-content{
  width: 100%;
  display: flex;
  justify-content: center;
}

.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}


.contact-wrapper{
  // height: 50vh;
  // margin: 30px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  // background-color: #787586;
  color:#4a4a4a;
  padding-bottom: 20px;
  // margin-top: -150px;
}





.contact-social{
  width: 75px;
  height: 75px;
  background: transparent;
  border-radius: 20px;
  border: 1px solid rgba(255,255,255,0.4);
  border-right: 1px solid rgba(255,255,255,0.2);
  border-bottom: 1px solid rgba(255,255,255,0.2);
  box-shadow: 0 5px 45px rgba(0,0,0,0.1);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  transition: 0.5s;
  overflow: hidden;
}

.contact-social:hover{
  transform: translateY(-20px);
}

.contact-social::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 100%;
  background: rgba(255,255,255,0.5);
  transform: skewX(45deg) translateX(150px);
}

.contact-social:hover::before{
  transform: skewX(45deg) translateX(-150px);
}

.contact-content{
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  width: 600px;
}


.social{
  font-size: 35px;
  color: #4a4a4a;
}

@media only screen and (max-width: 699px){

  .contact-content{
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    width: 350px;
  }

  .social{
    font-size: 30px;
    color: #4a4a4a;
  }

  .contact-social{
    width: 65px;
    height: 65px;
  }

}

@media only screen and (max-width: 654px){

  .contact-content{
    width: 330px;
  }

  .social{
    font-size: 30px;
    color: #4a4a4a;
  }

  .contact-social{
    width: 60px;
    height: 60px;
  }

}