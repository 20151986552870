.projects-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
  position: relative;
  margin-bottom: 40px;
}

.projects-container{
  position: relative;
  width: 1000px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // margin: 0 20px;
  .project-card{
    position: relative;
    height: 350px;
    display: flex;
    flex-direction: column;
    width: 45%;
    margin: 0 0 55px ;

    transform-style: preserve-3d;
    .project-title{
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      h3{
        font-size: 25px;
        width: 300px;
        margin: 0;
       color:#4a4a4a;
      }
    }
    .project-links{
      .svg-inline--fa.fa-w-16{
        margin-right: 10px;
      }
    }
  }
}

.box{
  position: relative;
  width: 450px;
  height: 300px;
  margin: 0;
  border-radius: 5px;
}

Tilt{
  width: 450px;
}

.imgBx{
  width: 100%;
  height: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // overflow: hidden;
  top: 0;
  left: 0;
  transition: 0.5s ease-in-out;
  border-radius: 5px;
  position: absolute;
  
  img{
    height: 100%;
    width: 100%;
    // top: 15%;
    // left: 0;
    // position: absolute;
    object-fit: cover;
    border-radius: 5px;

  }
}

.contentBx{
  position: absolute;
  top: 50%;
  left: 50px;
  right: 50px;
  background: #4a4a4af8;
  color: #fdf6f6;
  transform: translateZ(40px) scaleY(0);
  padding: 10px 25px 10px;
  transform-origin: top;
  transition: 0.5s;
  border-radius: 5px;
  transform-style: preserve-3d;
  display: flex;
  flex-direction: column;
  .time-frame{
    margin-bottom: 10px;
  }
}

.project-skills{
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  .project-skill{
    margin-right: 8px;
    i{
      font-size: 23px;
    }
  }
}


.box:hover{
  box-shadow: 0 50px 80px rgba(0,0,0,0.2);
}

.box:hover .contentBx{
  transform: translateZ(70px) scaleY(1);
}


// box-shadow: 0 5px 20px rgba(0,0,0,0.5);
// box-shadow: 0 5px 20px rgba(0,0,0,0.2);  

@media (max-width: 992px){

  .projects-container{
    width: 100%;
    flex-direction: column;
    align-items: center;
    .project-card{
      width: 80%;
      height: 450px;
    }
  }

  .imgBx{
    display: flex;
    justify-content: center;
    width: 100%;
    img{
      width: 100%;
    }
  }


}

@media only screen and (max-width: 799px){
  .projects-container{
    width: 100%;
    flex-direction: column;
    align-items: center;
    .project-card{
      width: 80%;
      display: flex;
      justify-content: center;
      .project-title{   
        width: 100%;
      }
    }
  }

  .imgBx{
    display: flex;
    justify-content: center;
    width: 100%;
    img{
      width: 100%;
    }
  }

  .box{
    width: 100%;
    height: 400px;
  }

  .project-links{
    display: flex;
  }
  
}

@media only screen and (max-width: 699px){
  .projects-container{
    width: 100%;
    flex-direction: column;
    align-items: center;
    .project-card{
      width: 100%;
      display: flex;
      justify-content: center;
      .project-title{   
        width: 100%;
        h3{
          margin-left: 10px;
        }
      }

    }
  }

  .contentBx{
    left: 30px;
    right: 30px;
  }
}